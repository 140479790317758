import { db } from "@config/firebase";
import { createNewOffer } from "@utils/functions/client/createNewOffer";
import { collection, doc, getDocs, onSnapshot, orderBy, query } from "firebase/firestore";
import { createContext, useContext, useEffect, useState } from "react";
import { Offer } from "types/local";
import { useAuth } from "./AuthContext";

const offersContext = createContext<{
  offers: Offer[];
  loadingOffers: boolean;
}>({
  offers: [],
  loadingOffers: true,
});

export const OffersProvider = (props: any) => {
  const { user } = useAuth();
  const [offers, setOffers] = useState<Offer[]>([]);
  const [loadingOffers, setLoadingOffers] = useState(false);

  useEffect(() => {
    if (!user || loadingOffers) return;
    setLoadingOffers(true);

    const userRef = doc(db, "users", user.uid);
    const offersRef = collection(userRef, "offers");
    const q = query(offersRef, orderBy("creation_timestamp"));

    const unsubscribe = onSnapshot(q, (offersColl) => {
      if (offersColl.empty) {
        //Create an offer with #1
        createNewOffer(user, 1).then((newOffer) => {
          setOffers([newOffer]);
        });
        return;
      }

      let offersArr: Offer[] = [];

      offersColl.docs.forEach((doc) => {
        //@ts-ignore
        offersArr.push({ ...doc.data(), id: doc.id });
      });

      setOffers(offersArr);
      setLoadingOffers(false);
    });

    return unsubscribe;
  }, [user]);

  return <offersContext.Provider value={{ offers, loadingOffers }} {...props} />;
};

export const useOffers = () => useContext(offersContext);
