import { db } from "@config/firebase";
import { User } from "firebase/auth";
import { addDoc, collection, doc } from "firebase/firestore";
import { Offer } from "types/local";

export const createNewOffer = async (user: User, numOfOffer: number): Promise<Offer> => {
  const userRef = doc(db, "users", user.uid);
  const newOfferObject = {
    name: numOfOffer === 1 ? "My main offer" : "Offer " + numOfOffer,
    valueProposition: null,
    benefits: null,
    extraInformation: null,
    cta: null,
    id: null,
    creation_timestamp: Date.now(),
  };

  const newOfferRef = await addDoc(collection(userRef, "offers"), newOfferObject);
  const newOffer = { ...newOfferObject, id: newOfferRef.id };
  return newOffer;
};
