import { db } from "@config/firebase";
import { doc, onSnapshot } from "firebase/firestore";
import { createContext, useContext, useEffect, useState } from "react";
import { UserData } from "types/local";
import { useAuth } from "./AuthContext";

const userContext = createContext<{
  userData: UserData | null;
  loadingUserData: boolean;
}>({
  userData: null,
  loadingUserData: false,
});

export const UserProvider = (props: any) => {
  const { user } = useAuth();

  const [userData, setUserData] = useState<UserData | null>(null);
  const [loadingUserData, setLoadingUserData] = useState(false);

  useEffect(() => {
    if (!user) return;

    setLoadingUserData(true);
    const unsubscribe = onSnapshot(doc(db, "users", user.uid), (document) => {
      if (!document) return;
      const docData = document.data();

      if (docData) {
        const data: UserData = {
          email: docData.email,
          name: docData.name,
          photoUrl: docData.photoUrl,
          company: docData.company || undefined,
          creditsUsed: docData.creditsUsed || 0,
          role: docData.role || undefined,
          senderName: docData.senderName || undefined,
          subscriptionEnds: docData.subscriptionEnds ? new Date(docData.subscriptionEnds * 1000) : undefined,
          creditsRemaining: docData.creditsRemaining || 0,
          plan: docData.plan || "free",
        };

        setLoadingUserData(false);
        setUserData(data);
      }
    });

    return unsubscribe;
  }, [user]);

  return <userContext.Provider value={{ userData, loadingUserData }} {...props} />;
};

export const useUserData = () => useContext(userContext);
