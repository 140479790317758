import { auth, db, functions } from "@config/firebase";
import { IS_DEV } from "@utils/constants";
import { GoogleAuthProvider, onAuthStateChanged, sendSignInLinkToEmail, signInWithPopup, signOut, User } from "firebase/auth";
import { doc, getDoc, setDoc } from "firebase/firestore";
import { httpsCallable } from "firebase/functions";
import { useRouter } from "next/router";
import { usePostHog } from "posthog-js/react";
import { createContext, useContext, useEffect, useState } from "react";

const checkUser = async (user: User) => {
  const userRef = doc(db, "users", user.uid);

  //Check if data exists
  const data = await getDoc(userRef);

  if (data.exists()) return;

  console.log("Trying to create user");

  //If data doesn't exist, create user in firestroe
  await setDoc(userRef, {
    uid: user.uid,
    name: user.displayName,
    email: user.email,
    photoUrl: user.photoURL,
  });
};

const authContext = createContext<{
  user: User | null;
  token: string | null;
  loading: boolean;
  loginWithGoogle: () => Promise<void>;
  loginWithMagicLink: (email: string) => Promise<void>;
  signOutSession: () => Promise<void>;
}>({
  user: null,
  loading: true,
  token: null,
  loginWithGoogle: async () => {},
  loginWithMagicLink: async () => {},
  signOutSession: async () => {},
});

const provider = new GoogleAuthProvider();

export const AuthProvider = (props: any) => {
  const [user, setUser] = useState<User | null>(null);
  const [loading, setLoading] = useState(true);
  const [token, setToken] = useState<string | null>(null);

  const router = useRouter();
  const posthog = usePostHog();

  //Login function
  const loginWithGoogle = async () => {
    if (loading) return;
    setLoading(true);
    try {
      const userCredentials = await signInWithPopup(auth, provider);
      setUser(userCredentials.user);
      //Track conversion if affiliate
      //@ts-ignore
      // gr?.("track", "conversion", { email: userCredentials.user.email });
    } catch (err) {
      console.error(err);
      //   setError(err);
    }
    setLoading(false);
  };

  const loginWithMagicLink = async (email: string) => {
    setLoading(true);
    //Construct ActionCodeSettings
    const actionCodeSettings = {
      url: `${IS_DEV ? "http://localhost:3000" : "https://app.ecold.ai"}/magiclink`,
      handleCodeInApp: true,
    };

    //Save email to local storage
    window.localStorage.setItem("emailForSignIn", email);

    //Send magic link
    await sendSignInLinkToEmail(auth, email, actionCodeSettings);
    setLoading(false);
  };

  const signOutSession = async () => {
    await signOut(auth);
    posthog?.reset();
    setUser(null);
  };

  //Check if user session is already
  useEffect(() => {
    onAuthStateChanged(auth, (_user) => {
      if (_user) {
        setUser(_user);
        _user.getIdToken(true).then((_token) => setToken(_token));

        checkUser(_user);

        posthog?.identify(_user.uid, {
          email: _user.email,
          name: _user.displayName,
        });
      } else {
        console.log("No user found");
        router.push("/login");
      }
      setLoading(false);
    });
  }, []);

  // useEffect(() => {
  //   if (user) {
  //     user.getIdToken(true).then((_token) => setToken(_token));

  //     //Get custom token for extension
  //     // TODO only if extension requests it?
  //     httpsCallable(
  //       functions,
  //       "createtoken"
  //     )(user.uid)
  //       .then((data) => {
  //         // console.log("Token", data);
  //         window.postMessage({
  //           type: "authToken",
  //           authToken: data.data,
  //         });
  //       })
  //       .catch((err) => console.log("Error getting custom token", err));
  //   }
  // }, [user]);

  return (
    <authContext.Provider
      value={{
        user,
        loading,
        loginWithGoogle,
        loginWithMagicLink,
        signOutSession,
        token,
      }}
      {...props}
    />
  );
};

export const useAuth = () => useContext(authContext);
