import "../styles/globals.css";
import type { AppProps } from "next/app";
import { AuthProvider } from "@utils/context/AuthContext";
import { UserProvider } from "@utils/context/UserContext";
import { OffersProvider } from "@utils/context/OffersContext";
import { useEffect } from "react";
import posthog from "posthog-js";
import { PostHogProvider } from "posthog-js/react";
import { useRouter } from "next/router";

// Check that PostHog is client-side (used to handle Next.js SSR)
if (typeof window !== "undefined") {
  posthog.init(process.env.NEXT_PUBLIC_POSTHOG_KEY || "", {
    api_host: process.env.NEXT_PUBLIC_POSTHOG_HOST || "https://app.posthog.com",
    // Enable debug mode in development
    loaded: (posthog) => {
      if (process.env.NODE_ENV === "development") posthog.debug();
    },
    capture_pageview: false, // Disable automatic pageview capture, as we capture manually
  });
}

export default function App({ Component, pageProps }: AppProps) {
  const router = useRouter();

  useEffect(() => {
    // Track page views
    const handleRouteChange = () => posthog?.capture("$pageview");
    router.events.on("routeChangeComplete", handleRouteChange);

    return () => {
      router.events.off("routeChangeComplete", handleRouteChange);
    };
  }, []);

  return (
    <div>Under maintenance</div>
  );

  return (
    <PostHogProvider client={posthog}>
      <AuthProvider>
        <UserProvider>
          <OffersProvider>
            <Component {...pageProps} />
          </OffersProvider>
        </UserProvider>
      </AuthProvider>
    </PostHogProvider>
  );
}

export { reportWebVitals } from "next-axiom";
