export const MAX_CREDITS = {
  free: 20,
  starter: 300,
  growth: 600,
  enterprise: 1500,
};

export const PRICES = {
  free: 0,
  starter: 39,
  growth: 69,
  enterprise: 149,
};

export const IS_DEV = process.env.NEXT_PUBLIC_IS_DEV || false;

export const EXTENSION_URL = "https://chrome.google.com/webstore/detail/ecoldai-ai-powered-cold-e/amobnkehefiaihfpefgabpdhdgbpepfm";